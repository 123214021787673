import React, { useState } from "react";
import "../header/header.css";
import "../../App.css";
import Logo from "../../assets/logotext.png";
import { Link } from 'react-router-dom';
import { Popover, OverlayTrigger, Button, Offcanvas } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import mergePdf from "../../assets/merge PDF.svg";
import pdfConverter from "../../assets/pdf converter.svg";
import compressPdf from "../../assets/compress pdf.svg";


const categories = [
    {
        title: "Optimize PDF",
        items: [
            { label: "Merge PDF", path: "/", icon: mergePdf },
            { label: "Split Pages", path: "/", icon: pdfConverter },
            { label: "Compress PDF", path: "/convertor/pdf-compress", icon: compressPdf }
        ]
    },
    {
        title: "Convert to PDF",
        items: [
            { label: "JPG to PDF", path: "/convertor/jpg-to-pdf", icon: mergePdf },
            { label: "Image to PDF", path: "/convertor/img-to-pdf", icon: pdfConverter },
            { label: "JPG to PNG", path: "/convertor/pdf-compress", icon: compressPdf },
            { label: "Word to PDF", path: "/convertor/word-to-pdf", icon: mergePdf },
            { label: "XLS to PDF", path: "/convertor/xls-to-pdf", icon: pdfConverter },
            { label: "HTML to PDF", path: "/convertor/html-to-pdf", icon: compressPdf },
            { label: "PPT to PDF", path: "/convertor/ppt-to-pdf", icon: pdfConverter }
        ]
    },
    {
        title: "Convert from PDF",
        items: [
            { label: "PDF to JPG", path: "/convertor/pdf-to-jpg", icon: mergePdf },
            { label: "PDF to Image", path: "/convertor/pdf-to-image", icon: pdfConverter },
            { label: "PDF to Word", path: "/convertor/pdf-to-word", icon: compressPdf },
            { label: "PDF to XLS", path: "/convertor/pdf-to-xls", icon: pdfConverter },
            { label: "PDF to PPT", path: "/convertor/pdf-to-ppt", icon: compressPdf }
        ]
    },
    {
        title: "Edit PDF",
        items: [
            { label: "Rotate PDF", path: "/convertor/rotate-pdf", icon: mergePdf },
            { label: "Add Watermark", path: "/convertor/add-watermark", icon: pdfConverter },
            { label: "Edit PDF", path: "/", icon: compressPdf }
        ]
    },
    {
        title: "PDF Security",
        items: [
            { label: "Protect PDF", path: "/convertor/protect-pdf", icon: mergePdf },
            { label: "Sign PDF", path: "/", icon: compressPdf }
        ]
    }
];


function Header() {
    const [activeCategory, setActiveCategory] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);

    const handleToggleOverlay = () => setShowOverlay(!showOverlay);

    return (
        <div className="">
            <header className="header shadow fixed-top bg-white d-flex align-items-center w-100 ">
                <Link to="/">
                    <img src={Logo} className="image-logo" alt="logo" />
                </Link>

                <div className="d-flex">
                    {categories.map((category, index) => (
                        <div 
                            key={index}
                            className="popover-container d-none d-md-block"
                            onMouseEnter={() => setActiveCategory(category.title)}
                            onMouseLeave={() => setActiveCategory(null)}
                        >
                            <Button className="btn-header bg-transparent border-0 ps-2 pe-2">
                                {category.title}
                            </Button>
                            {activeCategory === category.title && (
                                <Popover id="popover-content">
                                    <Popover.Body className="d-flex flex-column gap-3">
                                        <div className="d-flex flex-column gap-3">
                                            {category.items.map((item, idx) => (
                                                <Link 
                                                    key={idx} 
                                                    className="d-flex gap-2 align-items-center btn-element" 
                                                    to={item.path}
                                                    onClick={() => setActiveCategory(null)}
                                                >
                                                    <img src={item.icon} height={20} alt="" />
                                                    <div className="dropdown-ele">{item.label}</div>
                                                </Link>
                                            ))}
                                        </div>
                                    </Popover.Body>
                                </Popover>
                            )}
                        </div>
                    ))}
                    <Button
                        className="btn-header d-block d-md-none bg-transparent border-0"
                        onClick={handleToggleOverlay}
                    >
                        All PDF Tools
                         {/* &nbsp;<i className="fa-sharp fa-solid fa-chevron-down"></i> */}
                    </Button>
                </div>
                
                <Offcanvas show={showOverlay} onHide={handleToggleOverlay}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>All PDF Tools</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {categories.map((category, index) => (
                            <div key={index} className="category-section">
                                <h5>{category.title}</h5>
                                <div className="d-flex flex-column gap-2">
                                    {category.items.map((item, idx) => (
                                        <Link 
                                            key={idx} 
                                            className="d-flex gap-2 align-items-center btn-element" 
                                            to={item.path}
                                            onClick={handleToggleOverlay}
                                        >
                                            <img src={item.icon} height={20} alt="" />
                                            <div className="dropdown-ele">{item.label}</div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Offcanvas.Body>
                </Offcanvas>
            </header>
        </div>
    );
}

export default Header;
