import React, { useEffect } from "react";
import "./convertorsection.css";
import Header from "../../components/header/Header";
import { useLocation, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import JpgToPdf from "../../components/jpg-to-pdf/JpgToPdf";
import PdfToJpg from "../../components/pdf-to-jpg/PdfToJpg";
import PdfToWord from "../../components/pdf-to-word/PdfToWord";
import PdfToImage from "../../components/pdf-to-image/PdfToImage";
import HtmlToPdf from "../../components/html-to-pdf/HtmlToPdf";
import PngToPdf from "../../components/png-to-pdf/PngToPdf";
import PdfToPpt from "../../components/pdf-to-ppt/PdfToPpt";
import PdfCompress from "../../components/pdf-compress/PdfCompress";
import WordToPdf from "../../components/word-to-pdf/WordToPdf";
import XlsToPdf from "../../components/xls-to-pdf/XlsToPdf";
import PdfToXls from "../../components/pdf-to-xls/PdfToXls";
import PptToPdf from "../../components/ppt-to-pdf/PptToPdf";
import ImgToPdf from "../../components/img-to-pdf/ImgToPdf";
import PngToJpg from "../../components/png-to-jpg/PngToJpg";
import PdfPassword from "../../components/pdf-password/PdfPassowrd";
import MergePdf from "../../components/merge-pdf/MergePdf";
import RotatePdf from "../../components/rotate-pdf/RotatePdf";
import SplitPdf from "../../components/split-pdf/SplitPdf";
import AddWatermark from "../../components/add-watermark/AddWatermark";
import EditPdf from "../../components/edit-pdf/EditPdf";
import SignPdf from "../../components/sign-pdf/SignPdf";

const Convertor = () => {
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Header />
      {/* New Changes */}
      {id === "jpg-to-pdf" ? (
        <JpgToPdf id={id} />
      ) : id === "pdf-to-jpg" ? (
        <PdfToJpg id={id} isSingle={true} />
      ) : id === "pdf-to-word" ? (
        <PdfToWord id={id} />
      ) : id === "pdf-to-image" ? (
        <PdfToImage id={id} isSingle={true} />
      ) : id === "html-to-pdf" ? (
        <HtmlToPdf id={id} />
      ) : id === "png-to-pdf" ? (
        <PngToPdf id={id} />
      ) : id === "pdf-to-ppt" ? (
        <PdfToPpt id={id} />
      ) : id === "pdf-compress" ? (
        <PdfCompress id={id} isSingle={true} />
      ) : id === "png-to-jpg" ? (
        <PngToJpg id={id} isSingle={true} />
      ) : id === "word-to-pdf" ? (
        <WordToPdf id={id} isSingle={true} />
      ) : id === "xls-to-pdf" ? (
        <XlsToPdf id={id} isSingle={true} />
      ) : id === "pdf-to-xls" ? (
        <PdfToXls id={id} />
      ) : id === "ppt-to-pdf" ? (
        <PptToPdf id={id} isSingle={true} />
      ) : id === "img-to-pdf" ? (
        <ImgToPdf id={id} />
      ) : id === "pdf-password" ? (
        <PdfPassword id={id} isSingle={true} />
      ) : id === "merge-pdf" ? (
        <MergePdf id={id} />
      ) : id === "rotate-pdf" ? (
        <RotatePdf id={id} isSingle={true} />
      ) : id === "split-pdf" ? (
        <SplitPdf id={id} isSingle={true} />
      ) : id === "add-watermark" ? (
        <AddWatermark id={id} isSingle={true} />
      ) : id === "edit-pdf" ? (
        <EditPdf id={id} isSingle={true} />
      ) : id === "sign-pdf" ? (
        <SignPdf id={id} isSingle={true} />
      ) : (
        <></>
      )}
      <Toaster />
    </div>
  );
};

export default Convertor;
