import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";

const PdfToXls = ({ id }) => {
    const [message, setMessage] = useState("");
    const [files, setFiles] = useState([]);

    const convertFile = async (event) => {
        setMessage("");
        const selectedFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
        return;
    }

    return (
        <>
            {files.length === 0 ? <>
                <FileHeader id={id} />
                <div className="d-flex justify-content-center">
                    <div className="main-box d-flex align-items-center justify-content-center mt-5">
                        <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                            <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                                <h3>Drop your file here</h3>
                                <span>or</span>
                                <div>
                                    <label
                                        id="idlabel"
                                        className="mdl-button mdl-js-button mdl-button--raised"
                                    >
                                        <input
                                            type="file"
                                            multiple
                                            accept="application/pdf"
                                            id="exampleFormControlFile1"
                                            onChange={convertFile}
                                        />
                                        Upload a file
                                    </label>
                                </div>
                            </div>
                            <div>
                                <p className="text-muted mt-3">
                                    Maximum size allowed is 25MB. Supported formats are : {id.split("-")[0]}
                                </p>
                                {message && (
                                    <div className="text-success" style={{ color: "green" }}>
                                        {message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </> : <>
                <div className='w-100 d-flex justify-content-between main-container flex-wrap'>
                    <div className='images-side ml-5'>
                        <Imagebox files={files} setFiles={setFiles} type="pdf"/>
                    </div>
                    <div className='converter-side p-3 d-flex flex-column justify-content-between' style={{height: '600px'}}>
                    <h1 className='text-center'>{id.replace(/-/g, ' ').toUpperCase()}</h1>
                        {/* <div className='p-3 page-orientation'>
                <h6>Page orientation</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <div
                    className={`portrait ${selectedOrientation === 'portrait' ? 'selected' : ''}`}
                    onClick={() => handlePageOrientationClick('portrait')}
                  >
                    <p className='p-body'></p>
                    <p className='p-body-a'>Portrait</p>
                  </div>
                  <div
                    className={`landscape ${selectedOrientation === 'landscape' ? 'selected' : ''}`}
                    onClick={() => handlePageOrientationClick('landscape')}
                  >
                    <p className='l-body'></p>
                    <p className='l-body-a'>Landscape</p>
                  </div>
                </div>
              </div>
              <div className='p-3 page-orientation'>
                <h6>Margin</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <div
                    className={`img-body ${selectedMargin === 'no-margin' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('no-margin')}
                  >
                    <img src={smallimg} alt="logo" />
                    <p className='p-body-a'>No margin</p>
                  </div>
                  <div
                    className={`img-body ${selectedMargin === 'small' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('small')}
                  >
                    <img src={smallimg} alt="logo" />
                    <p className='l-body-a'>Small</p>
                  </div>
                  <div
                    className={`img-body ${selectedMargin === 'big' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('big')}
                  >
                    <img src={bigimg} alt="logo" />
                    <p className='l-body-a'>Big</p>
                  </div>
                </div>
              </div>
              <div className='mt-3 d-flex gap-3'>
                <p className='w-25 hello'>Width: </p>
                <select className='form-control' name="width" value={formData.width} onChange={handleChange}>
                  <option selected hidden>Select width</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="3000">3000</option>
                </select>
                <p className='w-25 hello'>Height: </p>
                <select className='form-control' name="height" value={formData.height} onChange={handleChange}>
                  <option selected hidden>Select height</option>
                  <option value="700">700</option>
                  <option value="1400">1400</option>
                  <option value="2100">2100</option>
                </select>
              </div>
              <div className="form-check form-switch p-0 d-flex justify-content-between mt-4">
                <p>Merge all images in one PDF file</p>
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
              </div> */}
                        <div>
                            <button className='btn-convert w-100'>
                                Convert to PDF <img src={rightarrow} alt="" width="30" height="18" />
                            </button>
                        </div>
                    </div>
                </div>
            </>}
            {files.length === 0 ? <>
                <FileFooter id={id} />
                <Footer />
            </> : <> </>}
        </>
    )
}
export default PdfToXls