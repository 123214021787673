import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import { api } from "../../api/api";
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PdfToPpt = ({ id }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();

  const navigate = useNavigate();

  const convertFile = async (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    return;
  };
  const handleConvertToPDF = async () => {
    if (files?.length > 10) {
      toast.error("File must be less than or equal to 10.");
      return;
    }
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      toast.error("One or more files exceed the 10 MB limit.");
      return;
    }
    setLoading(true);
    const formDataToSend = new FormData();

    // Object.keys(formData)?.forEach((key) => {
    //   formDataToSend.append(key, formData[key]);
    // });
    if (files.length > 1) {
      files.forEach((file) => {
        formDataToSend.append("file", file);
      });
      console.log({ formDataToSend });
      try {
        const response = await api().post(`${id}`, formDataToSend);
        console.log({ response });
        console.log(id);
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", {
            state: { fileUrl: response?.data?.data?.url },
          });
        }
      } catch (error) {
        console.log("Error ===> ", error);
        throw error;
      }
    } else {
      console.log("else part");
      formDataToSend.append("file", files[0]);
      console.log({ formDataToSend });
      try {
        const response = await api().post(`${id}`, formDataToSend);
       
        if (response.status === 200) {
          console.log({ response });
          setLoading(false);
          if (response?.data?.meta.status) {
            navigate("/convertor/file-ready", {
              state: { fileUrl: response?.data?.data?.url },
            });
          } // Create a new Blob from the response data
        } else {
          alert("File conversion failed. Please try again.");
        }
      } catch (error) {
        toast.error(error.message);
        throw error;
      }
    }
  };
  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        multiple
                        accept="application/pdf"
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 25MB. Supported formats are :{" "}
                    {id.split("-")[0]}
                  </p>
                  {message && (
                    <div className="text-success" style={{ color: "green" }}>
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-100 d-flex justify-content-between main-container flex-wrap">
            <div className="images-side ml-5">
              <Imagebox files={files} setFiles={setFiles} type="pdf" />
            </div>
            <div
              className="converter-side d-flex flex-column justify-content-between p-3"
              style={{ height: "600px" }}
            >
              <h1 className="text-center">
                {id.replace(/-/g, " ").toUpperCase()}
              </h1>
              {/* <div className='p-3 page-orientation'>
                <h6>Page orientation</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <div
                    className={`portrait ${selectedOrientation === 'portrait' ? 'selected' : ''}`}
                    onClick={() => handlePageOrientationClick('portrait')}
                  >
                    <p className='p-body'></p>
                    <p className='p-body-a'>Portrait</p>
                  </div>
                  <div
                    className={`landscape ${selectedOrientation === 'landscape' ? 'selected' : ''}`}
                    onClick={() => handlePageOrientationClick('landscape')}
                  >
                    <p className='l-body'></p>
                    <p className='l-body-a'>Landscape</p>
                  </div>
                </div>
              </div>
              <div className='p-3 page-orientation'>
                <h6>Margin</h6>
                <div className='card-body d-flex justify-content-between mt-3'>
                  <div
                    className={`img-body ${selectedMargin === 'no-margin' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('no-margin')}
                  >
                    <img src={smallimg} alt="logo" />
                    <p className='p-body-a'>No margin</p>
                  </div>
                  <div
                    className={`img-body ${selectedMargin === 'small' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('small')}
                  >
                    <img src={smallimg} alt="logo" />
                    <p className='l-body-a'>Small</p>
                  </div>
                  <div
                    className={`img-body ${selectedMargin === 'big' ? 'selected' : ''}`}
                    onClick={() => handleMarginSelection('big')}
                  >
                    <img src={bigimg} alt="logo" />
                    <p className='l-body-a'>Big</p>
                  </div>
                </div>
              </div>
              <div className='mt-3 d-flex gap-3'>
                <p className='w-25 hello'>Width: </p>
                <select className='form-control' name="width" value={formData.width} onChange={handleChange}>
                  <option selected hidden>Select width</option>
                  <option value="1000">1000</option>
                  <option value="2000">2000</option>
                  <option value="3000">3000</option>
                </select>
                <p className='w-25 hello'>Height: </p>
                <select className='form-control' name="height" value={formData.height} onChange={handleChange}>
                  <option selected hidden>Select height</option>
                  <option value="700">700</option>
                  <option value="1400">1400</option>
                  <option value="2100">2100</option>
                </select>
              </div>
              <div className="form-check form-switch p-0 d-flex justify-content-between mt-4">
                <p>Merge all images in one PDF file</p>
                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
              </div> */}
              <div>
                <button
                  className="btn-convert w-100"
                  onClick={handleConvertToPDF}
                >
                  Convert to PDF
                  <img src={rightarrow} alt="" width="30" height="18" />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : (
        <> </>
      )}
      <ToastContainer />
    </>
  );
};
export default PdfToPpt;
