import { useState, memo } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PdfViewer = ({ fileUrl, isSign, numPages, setNumPages }) => {
  
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div
      style={{ display: "flex", margin: "12px", gap: "20px", width: "100%" }}
    >
      <div
        style={{
          width: "120px",
          height: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
          border: "1px solid #ddd",
          padding: "5px",
        }}
      >
        <Document
          file={fileUrl}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div
              key={index}
              style={{ marginBottom: "10px", cursor: "pointer" }}
              onClick={() => {
                setCurrentPage(index + 1);
              }}
            >
              <Page
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={100}
              />
            </div>
          ))}
        </Document>
      </div>
      <div style={{ flex: 1 }}>
        <div>
         {!isSign && <h5>{`Following editable content was found in Page-${currentPage}`}</h5>}
          <Document
            file={fileUrl}
            onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            renderMode={isSign? "canvas" :"none"}
          >
            <Page pageNumber={currentPage} renderTextLayer={isSign?false:true} width={0} />
          </Document>
        </div>
      </div>
    </div>
  );
};

// Export the memoized component
export default memo(PdfViewer);
