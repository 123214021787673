import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { features } from "../../utils";
import { DropdownButton } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import data from "../../assets/statics/static-content.json";
import '../file-header/fileheader.css'

function FileHeader ({id}) {
    const [staticData, setStaticData] = useState(null);
    const navigate = useNavigate();

    const handleSelect = (eventKey) => {
        navigate(`/convertor/${eventKey}`);
      };

    useEffect(() => {
        setStaticData(data[id]);
      }, [id]);

    return(
        <>
        <div className="">
                    <div className="main-heading">
                        <h1>Free {id.toUpperCase().replaceAll("-", " ")} Converter</h1>
                        <p className="text-muted">{staticData?.title}</p>
                    </div>
                </div>

                <div className="d-flex justify-content-center align-items-center gap-3 mt-5">
                    <p className="convert-file">Converting file:</p>
                    <span>
                        <DropdownButton title={id}>
                            {features.map((feature, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => handleSelect(feature.id.replace(/ /g, "-"))}
                                >
                                    {feature.name}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </span>
                </div>
        </>
    )
}

export default FileHeader;