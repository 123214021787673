import { IconArrowBackUp, IconArrowForwardUp } from "@tabler/icons-react";
import React, { useState, useRef } from "react";

const AddSignature = ({ setSignature }) => {
  const [activeTab, setActiveTab] = useState(0);
  const canvasRef = useRef(null);
  const [drawing, setDrawing] = useState(false);
  const [strokeColor, setStrokeColor] = useState("#555555");
  const [files, setFiles] = useState([]);

  const convertFile = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  // Start drawing
  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.beginPath();
    context.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.strokeStyle = strokeColor;
    setDrawing(true);
  };

  // Draw on canvas
  const draw = (e) => {
    if (!drawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    context.stroke();
  };

  // Stop drawing
  const stopDrawing = () => {
    setDrawing(false);
  };

  // Clear the canvas
  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  // Save the signature as a base64 image
  const saveSignature = () => {
    const canvas = canvasRef.current;
    const signatureData = canvas.toDataURL("image/png");
    setSignature(signatureData);
  };

  return (
    <div
      className="modal fade"
      id="addSignatureModal"
      tabIndex="-1"
      aria-labelledby="addSignatureModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addSignatureModalLabel">
              Set your signature details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body py-4">
            <div
              style={{
                backgroundColor: "#E9E9E9",
                height: "54px",
                borderRadius: "6px",
              }}
              className="d-flex align-items-center gap-5 px-4"
            >
              {["Signature", "Upload Image"].map((tab, index) => (
                <p
                  key={index}
                  className={`m-0 ${
                    activeTab === index
                      ? "active-signature-tab"
                      : "signature-tab"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {tab}
                </p>
              ))}
              {/* <div
                className="d-flex gap-4"
                style={{ width: "fit-content", marginLeft: "auto" }}
              >
                <IconArrowBackUp color="#A7A7A7" size="30px" />
                <IconArrowForwardUp color="#A7A7A7" size="30px" />
              </div> */}
            </div>
            <div className="signature-pad">
              {activeTab === 0 ? (
                <>
                  <canvas
                    ref={canvasRef}
                    style={{
                      border: "1px solid #ccc",
                      display: "block",
                      margin: "0px auto",
                      borderRadius: "6px",
                    }}
                    width={400}
                    height={180}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={stopDrawing}
                  />
                  <hr />
                  <button
                    type="button"
                    className="btn"
                    style={{
                      color: "#2E7EFB",
                      marginLeft: "auto",
                      display: "block",
                    }}
                    onClick={clearCanvas}
                  >
                    Clear
                  </button>
                </>
              ) : (
                <div>
                  <label
                    id="idlabel"
                    className="mdl-button mdl-js-button mdl-button--raised m-auto mt-5"
                  >
                    <input
                      type="file"
                      accept=".png"
                      id="exampleFormControlFile1"
                      onChange={convertFile}
                    />
                    Browse from PC
                  </label>
                  <p className="text-center mt-3">Format supported: PNG only</p>
                  {files[files?.length - 1] && (
                    <img
                      src={URL.createObjectURL(files[files?.length - 1])}
                      width={400}
                      className="m-auto d-block mt-3"
                    />
                  )}
                </div>
              )}
            </div>
            {activeTab === 0 && (
              <div className="d-flex align-items-center gap-5">
                <div className="d-flex align-items-center gap-2">
                  <p className="m-0">Color:</p>
                  {["#555555", "#DC3545", "#007BFF", "#28A745"].map((clr) => (
                    <div
                      className="color-circle"
                      style={{ backgroundColor: clr }}
                      onClick={() => {
                        clearCanvas();
                        setStrokeColor(clr);
                      }}
                    />
                  ))}
                </div>
                {/* <div className="d-flex align-items-center gap-2">
                <p className="m-0">Font:</p>
                <select className="font-select">
                  <option>Change Style</option>
                </select>
              </div> */}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#FF6E6C",
                height: "54px",
                width: "126px",
              }}
              onClick={() => {
                if (activeTab === 0) {
                  saveSignature();
                } else
                  setSignature(files?.[files?.length - 1]);
              }}
              data-bs-dismiss="modal"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSignature;
