import React, { useRef, useState, useEffect } from "react";
import "./imagebox.css";
import pluslogo from "../../assets/add-icon.png";
import rotateicon from "../../assets/rotate-icon.svg";
import pdficon from "../../assets/pdf-svgrepo-com.svg";  
import word from "../../assets/doc-svgrepo-com.svg";  
import pptIcon from '../../assets/ppt-svgrepo-com.svg'; // PowerPoint icon
import compressIcon from '../../assets/7z-svgrepo-com.svg'; // Compressed file icon
import xlsIcon from '../../assets/xls-svgrepo-com.svg'; // Excel icon
import { readFileAsDataURL, checkType } from "../../shared";

const Imagebox = ({ files, setFiles, type, isSingle, orientation, margin, pageSize }) => {
  const [imagePreviews, setImagePreviews] = useState([]);
  const ref = useRef();
  let resType = checkType(type);

  const addMore = () => {
    ref.current.click();
  };

  const fileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const removeImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const rotateImage = (id) => {
    console.log("Rotate image", id);
  };

  const isPdfFile = (file) => file.type === "application/pdf";
const isWord = (file) => file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
const isPptFile = (file) => file.type === "application/vnd.ms-powerpoint" || file.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation";
const isCompressedFile = (file) => file.type === "application/zip" || file.type === "application/x-rar-compressed";
const isXlsFile = (file) => file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

 useEffect(() => {
  const loadImagePreviews = async () => {
    try {
      const previews = await Promise.all(
        files.map((file) => {
          if (isPdfFile(file)) {
            return pdficon;  // Use PDF icon
          } else if (isWord(file)) {
            return word;  // Use Word icon
          } else if (isPptFile(file)) {
            return pptIcon;  // Use PowerPoint icon
          } else if (isCompressedFile(file)) {
            return compressIcon;  // Use Compressed file icon
          } else if (isXlsFile(file)) {
            return xlsIcon;  // Use Excel icon
          } else {
            return readFileAsDataURL(file);  // Default to reading file as image
          }
        })
      );
      setImagePreviews(previews);
    } catch (error) {
      console.error("Error reading files:", error);
    }
  };

  if (files.length > 0) {
    loadImagePreviews();
  } else {
    setImagePreviews([]);
  }
}, [files]);

  return (
    <>
      <div
        className="d-flex flex-wrap gap-4"
        style={{
          marginTop: "10%",
          maxHeight: "600px",
          overflowY: imagePreviews.length > 5 ? "scroll" : "visible",
        }}
      >
        {imagePreviews && imagePreviews.length > 0 ? (
          imagePreviews.slice(0, 10).map((item, index) => (
            <div
              className="card d-flex align-items-center justify-content-center"
              key={index}
              style={{ width: "350px", position: "relative", height: "350px" }}
            >
              <div className="d-flex justify-content-end">
                <form>
                  <div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        rotateImage(index);
                      }}
                      className="remove-button "
                      style={{ position: "absolute", top: "10px", left: "0px" }}

                    >
<p
  className="text-center text-muted"
  style={{
    background: "red",
    color: "white",
     borderRadius: "50%",
     display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",  
  }}
>
  <b style={{color:"white"}}>{index + 1}</b>
</p>
                   </button>
                  </div>
                  <div>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        removeImage(index);
                      }}
                      className="remove-button"
                      style={{ position: "absolute", top: "10px", right: "10px" }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="10" fill="#000" />
                        <path
                          d="M8 8L16 16M8 16L16 8"
                          stroke="#FFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </form>
              </div>
              <div
                className="card-body border border-danger border-0 justify-content-center"
                style={{
                  width:
                    pageSize === "A4"
                      ? "300px"
                      : pageSize === "Letter"
                      ? "180px"
                      : pageSize === "Tabloid"
                      ? "270px"
                      : "190px",
                  height:
                    pageSize === "A4"
                      ? "280px"
                      : pageSize === "Letter"
                      ? "200px"
                      : pageSize === "Tabloid"
                      ? "200px"
                      : "250px",
                  margin:
                    margin === "none"
                      ? "80px"
                      : margin === "small"
                      ? "60px"
                      : margin === "big"
                      ? "45px"
                      : "0",
                  transform: orientation === "portrait" ? "rotate(90deg)" : "none",
                  background: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={item}
                  className="card-img-top"
                  alt="Preview"
                  style={{
                    transform: orientation === "portrait" ? "rotate(-90deg)" : "none",
                    transition: "transform 0.3s ease",
                    width: "150px",
                    height: "150px",
                  }}
                />
              </div>

              {/* <p className="text-center text-muted">file_{index + 1}</p> */}
            </div>
          ))
        ) : (
          <></>
        )}

        {!isSingle && imagePreviews.length < 10 && (
          <div className="addmore-button d-flex align-items-center">
            <div
              className="card-one"
              onClick={addMore}
              style={{ width: "350px", height: "350px" }}
            >
              <img src={pluslogo} alt="Add More" />
              <input
                type="file"
                accept="image/*,application/pdf"
                multiple
                ref={ref}
                onChange={fileChange}
              />
              <span style={{ color: "#a5a0a0" }}>Add More ▼</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Imagebox;
