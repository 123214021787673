import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import Footer from "../footer/Footer";
import { api } from "../../api/api";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";

const PdfPassword = ({ id, isSingle }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ password: "", confirmPassword: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const navigate = useNavigate();

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const convertFile = (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "password") setPasswordStrength(calculatePasswordStrength(value));
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length > 5) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    return strength;
  };

  const handleSubmit = async () => {
    if (!files.length) {
      toast.error("Please select a file first.");
      return;
    }
    if (files.length > 10) {
      toast.error("File must be less than or equal to 10.");
      return;
    }
    const { password, confirmPassword } = formData;
    if (!password) {
      toast.error("Password is required.");
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length) {
      toast.error("One or more files exceed the 10 MB limit.");
      return;
    }
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("password", password);
    if (files.length > 1) {
      formDataToSend.append("files", files);
    } else {
      formDataToSend.append("file", files[0]);
    }
    try {
      const response = await api().post(`${id}/single`, formDataToSend);
      setLoading(false);
      if (response?.data?.meta.status) {
        navigate("/convertor/file-ready", { state: { fileUrl: response?.data?.data?.url } });
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <>
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label id="idlabel" className="mdl-button mdl-js-button mdl-button--raised">
                      <input
                        type="file"
                        multiple={!isSingle}
                        accept="application/pdf"
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <p className="text-muted mt-3">
                  Maximum size allowed is 25MB. Supported formats are: {id.split("-")[0]}
                </p>
                {message && <div style={{ color: "green" }}>{message}</div>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <Loader title={`Compressing ${replaceId(id)}`} />
          ) : (
            <div className="w-100 d-flex justify-content-between main-container flex-wrap">
              <div className="images-side ml-5">
                <Imagebox files={files} setFiles={setFiles} type="pdf" isSingle={isSingle} />
              </div>
              <div className="converter-side p-3" style={{ height: "88vh" }}>
                <h1 className="text-center">{id.replace(/-/g, " ").toUpperCase()}</h1>
                <div className="main d-flex flex-column justify-content-between mt-4 p-0" style={{ height: "80%" }}>
                  <div className="containerr d-flex flex-column gap-4 p-3">
                    <div className="mt-3 d-flex align-items-center gap-2 position-relative">
                      <FaLock className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }} />
                      <input
                        className="form-control w-100"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        placeholder="Type Password"
                        style={{ paddingLeft: "35px", paddingRight: "35px" }}
                      />
                      <div
                        className="position-absolute"
                        style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                    <div className="mt-3 d-flex align-items-center gap-2 position-relative">
                      <FaLock className="position-absolute" style={{ left: "10px", top: "50%", transform: "translateY(-50%)" }} />
                      <input
                        className="form-control w-100"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirmPassword"
                        onChange={handleChange}
                        placeholder="Repeat Password"
                        style={{ paddingLeft: "35px", paddingRight: "35px" }}
                      />
                      <div
                        className="position-absolute"
                        style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                        onClick={toggleShowConfirmPassword}
                      >
                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                    <div className="password-strength mt-3">
                      <p>Password Strength:</p>
                      <div style={{ height: "10px", width: "100%", backgroundColor: "#ddd", borderRadius: "5px", overflow: "hidden" }}>
                        <div
                          style={{
                            width: `${passwordStrength * 20}%`,
                            height: "100%",
                            backgroundColor: passwordStrength < 3 ? "red" : passwordStrength < 5 ? "orange" : "green",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <button className="btn-convert w-100" onClick={handleSubmit}>
                    Set Password <img src={rightarrow} alt="" width="30" height="18" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
       <ToastContainer />
    </>
  );
};

export default PdfPassword;
