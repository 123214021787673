import React, { useEffect, useState } from "react";
import { features } from "../../utils";
import { useNavigate } from "react-router-dom";
import "./continue.css";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
const Continue = ()=>{
const navigate = useNavigate();
const handleClick = (id) => {
    navigate(`/convertor/${id}`);
    // Handle navigation to the next page with the given ID
  };
    return(
<div className="container d-flex justify-content-center align-items-center min-vh-100">
  <div className="row card-bodys p-3 text-center" style={{ width: "80%" }}>
  <h1 className="mb-4 ml-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
  Continue to...
</h1>
    <div className="mt-3 d-flex flex-wrap justify-content-center gap-3">
      {features.map((feature, index) => (
        <div
          key={index}
          className="cards p-3 d-flex flex-row align-items-center justify-content-between"
          onClick={() => handleClick(feature.id.replace(/ /g, "-"))}
          style={{
            cursor: "pointer",
            border: "1px solid #ddd",
            borderRadius: "8px",
            minWidth: "250px",
            flex: "1 1 calc(33% - 1rem)", // Responsive card width
            maxWidth: "400px",
          }}
        >
          <div className="d-flex align-items-center gap-3">
            <img
              src={feature.image}
              className="App-logo"
              alt="logo"
              style={{ width: "50px", height: "50px" }}
            />
            <h1 className="heading m-0" style={{ fontSize: "1.5rem" }}>
              {feature.name}
            </h1>
          </div>
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{
              fontSize: "25px",
              color: "#333",
            }}
          />
        </div>
      ))}
    </div>
  </div>
</div>


   
    )
}
export default Continue