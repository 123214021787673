import { IconChevronDown, IconUser } from "@tabler/icons-react";
import React from "react";

const AddSignee = () => {
  return (
    <div
      className="modal fade"
      id="addSigneeModal"
      tabIndex="-1"
      aria-labelledby="addSigneeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="addSigneeModalLabel">
              Invite Signees
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body py-5">
            <form className="d-flex gap-3 align-items-end">
              <div style={{ width: "305px" }}>
                <label htmlFor="signeeName" className="form-label">
                  Signee Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="signeeName"
                  placeholder="Enter signee name"
                  style={{ height: "46px" }}
                />
              </div>
              <div style={{ width: "305px" }}>
                <label htmlFor="signeeEmail" className="form-label">
                  Signee Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="signeeEmail"
                  placeholder="Enter signee email"
                  style={{ height: "46px" }}
                />
              </div>
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#FFFFFF",
                  height: "46px",
                  border: "2px solid #FF6E6C",
                  width: "100px",
                }}
              >
                Add
              </button>
            </form>
            <div className="mt-5">
              <p style={{ color: "#A7A7A7" }}>Who can sign this document</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-3 align-items-center">
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "25px",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <IconUser color="#646464" />
                  </div>
                  <div>
                    <p className="text-black text-lg m-0">
                      Qasim Ali Khan Niazi
                    </p>
                    <p
                      className="m-0"
                      style={{ fontSize: "13px", color: "#898989" }}
                    >
                      Qasimalikhan.niazi@gmail.com
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn"
                  style={{ backgroundColor: "#C8C8C8", fontSize: "13px" }}
                >
                  Pending <IconChevronDown />{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#FF6E6C",
                height: "54px",
                width: "126px",
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSignee;
