import { IconTextResize, IconEdit, IconTrashX } from '@tabler/icons-react';

const InputWithIcons = ({ph, value, onChange}) => {
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <IconTextResize
                style={{
                    position: 'absolute',
                    left: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    color: '#242424',
                }}
                size="32px"
            />
            <input
                className="w-100 py-3"
                placeholder={ph}
                value={value}
                onChange={onChange}
                style={{
                    height: '64px',
                    border: '0px',
                    borderRadius: '8px',
                    paddingLeft: '52px',
                    paddingRight: '80px',
                }}
            />
            {/* <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', display: 'flex', gap: '5px' }}>
                <IconEdit style={{ color: '#646464' }} size="18px" />
                <IconTrashX style={{ color: '#646464' }} size="18px" />
            </div> */}
        </div>
    );
};

export default InputWithIcons;
