import React from "react";
import Header from "../../components/header/Header";
import "./file-ready.css";
import Continue from "../../components/continue-to/Continue";
import 'font-awesome/css/font-awesome.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShareNodes, faRotate } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const FileReady = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const handleGoBack = () => {
        navigate(-1); // Moves back one step in history
      };
    const downloadHandler = () => {
        const fileUrl = location.state.fileUrl;
        const link = document.createElement('a');
        link.href = location.state.fileUrl;
        link.download = '';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <>
            <Header />

            <div className="main-top uni-padding d-flex gap-5">
                <div className="col-lg-12">
                    <div className="d-flex justify-content-center text-center">
                        <div className="file-sec">
                            <h1>File Ready</h1>
                            <p>2024-01-30 220532 <span className="text-muted">.dox</span></p>
                            <button className="btn-download" onClick={downloadHandler}><FontAwesomeIcon className="me-2" icon={faDownload} />Download</button><br />
                            {/* <button className="btn-download" onClick={downloadHandler}><FontAwesomeIcon className="me-2" icon={faDownload} />Download Merge PDF</button><br /> */}
                            <button className="mt-2 btn-share"><FontAwesomeIcon className="me-2" icon={faShareNodes} />Share</button>
                            <p className="mt-4 text-muted"style={{textAlign:"center"}} onClick={handleGoBack}><FontAwesomeIcon className="me-2" icon={faRotate} />Start Over</p>
                        </div>
                    </div>
                    <div>
                        <Continue />
                    </div>
                </div>


                {/* <div className=" col-lg-4  col-md-4 adds"> Large Ads</div> */}

            </div>
        </>

    )
}

export default FileReady;