import React, { useState } from "react";
import Imagebox from "../pdfConveter/Imagebox";
import rightarrow from "../../assets/rightarrow.png";
import FileHeader from "../file-header/FileHeader";
import FileFooter from "../file-footer/FileFooter";
import { replaceId, MAX_FILE_SIZE } from "../../shared";
import { api } from "../../api/api";
import Loader from "../loader/Loader";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const PdfToWord = ({ id }) => {
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    noOcr: true,
  });
  const navigate = useNavigate();

  const convertFile = async (event) => {
    setMessage("");
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    return;
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };


  const handleConvertToPDF = async (e) => {
    e.preventDefault();
    if (files?.length > 10) {
      toast.error("File must be less than or equal to 10.");
      return;
    }
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (invalidFiles.length > 0) {
      toast.error("One or more files exceed the 10 MB limit.");
      return;
    }
    setLoading(true);
    const formDataToSend = new FormData();

    Object.keys(formData)?.forEach((key) => {
      formDataToSend.append(key, formData[key]);
    });
    if (files.length > 1) {
      files.forEach((file) => {
        formDataToSend.append("file", file);
      });
      
      try {
        const response = await api().post(`${id}/single`, formDataToSend);
      
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", {
            state: { fileUrl: response?.data?.data?.url },
          });
        }
      } catch (error) {
        console.log("Error ===> ", error);
        throw error;
      }
    } else {
      
      formDataToSend.append("file", files[0]);
      
      try {
        const response = await api().post(`${id}/single`, formDataToSend);
        setLoading(false);
        if (response?.data?.meta.status) {
          navigate("/convertor/file-ready", {
            state: { fileUrl: response?.data?.data?.url },
          });
        }
      } catch (error) {
        toast.error(error.message);
        throw error;
      }
    }
  };

  return (
    <div >
      {files.length === 0 ? (
        <>
          <FileHeader id={id} />
          <div className="d-flex justify-content-center">
            <div className="main-box d-flex align-items-center justify-content-center mt-5">
              <div className="inner-box d-flex align-items-center justify-content-center flex-column gap-3">
                <div className="d-flex file-uploaded align-items-center justify-content-center gap-3">
                  <h3>Drop your file here</h3>
                  <span>or</span>
                  <div>
                    <label
                      id="idlabel"
                      className="mdl-button mdl-js-button mdl-button--raised"
                    >
                      <input
                        type="file"
                        multiple
                        accept="application/pdf"
                        id="exampleFormControlFile1"
                        onChange={convertFile}
                      />
                      Upload a file
                    </label>
                  </div>
                </div>
                <div>
                  <p className="text-muted mt-3">
                    Maximum size allowed is 25MB. Supported formats are :{" "}
                    {id.split("-")[0]}
                  </p>
                  {message && (
                    <div className="text-success" style={{ color: "green" }}>
                      {message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-100 d-flex justify-content-between main-container flex-wrap" style={{height:"88vh",}}>
            <div className="images-side ml-5">
              <Imagebox files={files} setFiles={setFiles} type="pdf" />
            </div>
            <div
  className="converter-side p-3 d-flex flex-column justify-content-between"
  style={{ height: "88vh" }}
>
  <div style={{ flex: 1 }}>
    <h1 className="text-center">
      {id.replace(/-/g, " ").toUpperCase()}
    </h1>

    <div className="containerr d-flex align-items-center justify-content-between gap-5 p-3">
      <div className="d-flex flex-column">
        <span className="title-form">No OCR</span>
        <span className="title-desc">Less quality, high compression</span>
      </div>
      <input
        className="form-check-input"
        type="checkbox"
        name="noOcr"
        checked={formData.noOcr}
        onChange={handleChange}
      />
    </div>
  </div>

  <div>
    <button
      className="btn-convert w-100"
      onClick={handleConvertToPDF}
      style={{ marginTop: 'auto' }} // Push the button to the bottom
    >
      Convert to PDF
      <img src={rightarrow} alt="" width="30" height="18" />
    </button>
  </div>
</div>

          </div>
        </>
      )}
      {files.length === 0 ? (
        <>
          <FileFooter id={id} />
          <Footer />
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </div>
  );
};
export default PdfToWord;
